import React from "react";
import dayjs from "dayjs";
import { SettingContext } from "./Context";
import Cookies from "js-cookie";

const MyGlobalData = ({ children }) => {
  let globalData = {
    URL: window.location.hostname,
    // API_URL: document.getElementById("API_URL").value,
    API_URL: "https://backend.vibgyoroffplan.com",
    // API_URL: "https://backend.vibgyor.rabs.support",
    // API_URL: "http://localhost:3003",
    variable: {
      DateTime: dayjs().format("YYYY-MM-DD HH:mm:ss"),
    },
    CompanyName: window.location.hostname,
    Version: "0.5",
    currScreen: "",
    login: {
      path: React.lazy(() => import("../pages/Auth/Login/Login")),
    },
    forgetpassword: {
      path: React.lazy(() =>
        import("../pages/Auth/ForgotPassword/ForgotPassword")
      ),
    },
    module_privilege: "",
    user_data: "",
    data_array: [],
    pages: {
      components: {
        leftsidebar: React.lazy(() =>
          import("../components/Leftsidebar/Leftsidebar")
        ),
      },
      dashboard: React.lazy(() => import("../pages/Dashboard/Dashboard")),
      forgotpassword: React.lazy(() =>
        import("../pages/Auth/ForgotPassword/ForgotPassword")
      ),
      changepassword: React.lazy(() =>
        import("../pages/Auth/ChangePassword/ChangePassword")
      ),

      properties: {
        totalproperty: React.lazy(() => import("../pages/Properties/totalproperty")),
        addproperty: React.lazy(() => import("../pages/Properties/addproperty")),
        propertydetail: React.lazy(() => import("../pages/Properties/propertyDetail")),
        updateproperty:React.lazy(() => import("../pages/Properties/updateproperty"))
      },

      leads: {
        totalleads: React.lazy(() => import("../pages/Lead/totalLead")),
        addleads: React.lazy(() => import("../pages/Lead/addLead")),
        leadDetail: React.lazy(() => import("../pages/Lead/leadDetail")),
        updatelead: React.lazy(() => import("../pages/Lead/updateLead")),
      },

      dynamicfields: {
        developer:{
          developer:React.lazy(() => import("../pages/DynamicFields/developer/developer")),
          // addDeveloper:React.lazy(() => import("../pages/DynamicFields/developer/addDeveloper")),
          developerDetail:React.lazy(() => import("../pages/DynamicFields/developer/developerDetail")),
          // updateDeveloper:React.lazy(() => import("../pages/DynamicFields/developer/updateDeveloper")),
        },
        area:React.lazy(() => import("../pages/DynamicFields/area/area")),
        addArea:React.lazy(() => import("../pages/DynamicFields/area/addArea")),
        updateArea:React.lazy(() => import("../pages/DynamicFields/area/updateArea")),
        areaDetail:React.lazy(() => import("../pages/DynamicFields/area/areaDetail")),
        configuration:React.lazy(() => import("../pages/DynamicFields/configuration")),
        agents:React.lazy(() => import("../pages/DynamicFields/agents")),
        amenities:React.lazy(() => import("../pages/DynamicFields/amenities")),
      },

      other: {
        loader: React.lazy(() => import("../pages/Other/Loader")),
        profile: React.lazy(() => import("../pages/Other/Profile/Profile")),
        apiintegration: React.lazy(() =>
          import("../pages/Other/ApiIntegration/ApiIntegration")
        ),
        integrationdetails: React.lazy(() =>
          import("../pages/Other/ApiIntegration/Integrationdetails")
        ),
      },
      // broker: {
      //   allbroker: React.lazy(() => import("../pages/Broker/AllBroker")),
      // },
    },
  };

  return (
    <SettingContext.Provider value={{ globalData }}>
      {children}
    </SettingContext.Provider>
  );
};

export default MyGlobalData;
